<template>
  <div>

    <!--Image bar -->
    <div class="tw-w-5/12 sm:tw-w-2/12 tw-px-4">
      <h4 class="titlePage titlePage_subtitle titlePage_green">Logo</h4>
    </div>

    <div class="tw-flex tw-flex-wrap tw-pt-3">
      <div class="tw-w-5/12 sm:tw-w-2/12 tw-px-4">
        <img
          :src="businessDetails.logo_url"
          alt="..."
          class="tw-shadow-lg tw-rounded tw-max-w-full tw-h-auto tw-align-middle tw-border-none"/>
      </div>
      <div>
        <button
          class="btn btn_default tw-px-5 tw-bg-secondary tw-text-white tw-py-2"
          @click="show = true">
          <svg-icon name="solid/floppy-disk"/>
          Upload
        </button>
        <button
          class="btn btn_default tw-px-5 tw-bg-secondary tw-text-white tw-py-2"
          @click="removeLogo()">
          <svg-icon name="solid/trash"/>
          Remove
        </button>
      </div>
      <UploadImageAndCropVue v-if="show" @crop-success="cropSuccess" @close="show = false"/>

        <!-- <my-upload v-model="show"
                :maxSize="500000"
                 :height="300"
                 :width="1000"
                 field="img"
                 img-format="png"
                 lang-type="en"
                 @crop-success="cropSuccess"
                 :noCircle="true"
                 :noSquare="true"
      ></my-upload>   -->
    </div>
    <!--Image bar ends -->

    <Form
      id="update_business_details"
      ref="updateBusinessDetailsREF"
      v-slot="{ errors }"
      autocomplete="off"
      @submit="submitBusinessDetails"

    >

      <div class="tw-flex tw-flex-wrap tw-overflow-hidden md:-mx-4 lg:-mx-4 xl:-mx-4">

        <!-- Left column starts -->
        <div
          class="tw-w-full tw-overflow-hidden md:tw-my-4 md:tw-px-4 md:tw-w-1/2 lg:tw-my-4 lg:tw-px-4 lg:tw-w-1/2 xl:tw-my-4 xl:tw-px-4 xl:tw-w-1/2">

          <h4 class="titlePage titlePage_subtitle titlePage_green">Business Details</h4>
          <label class="form__label">
            <span class="form__labelTitle">Business Name*</span>
            <Field v-model="businessDetails.business_name"
                   :rules="{required:true, max:128}"
                   class="form__input"
                   label="business name" name="business_name"
                   placeholder="Business Name*"
                   type="text"
            />
            <span class="form__error">{{ errors.business_name }}</span>
          </label>
          <label class="form__label">
            <span class="form__labelTitle">Trading Name*</span>
            <Field v-model="businessDetails.trading_name"
                   :rules="{required:true, max:128}"
                   class="form__input"
                   label="trading name" name="trading_name"
                   placeholder="Trading Name*"
                   type="text"
            />
            <span class="form__error">{{ errors.trading_name }}</span>
          </label>

          <label class="label-switch switch-success">
            <input id="status" v-model="sameAsBusinessName"
                   class="switch switch-bootstrap status" name="status" type="checkbox"
                   value="1">
            <span class="lable"> Same as business name</span></label>


          <label class="form__label">
          <label class="label-switch switch-success">
              <input id="register_for_gst" v-model="businessDetails.register_for_gst"
                     class="switch switch-bootstrap status" name="register_for_gst" type="checkbox"
                     :checked="businessDetails.register_for_gst">
              <span class="lable"> Registered for GST</span>
          </label>
          </label>

          <label class="form__label">
            <span class="form__labelTitle">ABN</span>
            <Field v-model="businessDetails.abn"
                   :rules="{max:11}"
                   class="form__input"
                   label="abn" name="abn"
                   placeholder="ABN"
                   type="text"
            />
            <span class="form__error">{{ errors.abn }}</span>
          </label>

          <label class="form__label">
            <span class="form__labelTitle">ACN</span>
            <Field v-model="businessDetails.acn"
                   :rules="{max:9}"
                   class="form__input"
                   label="acn" name="acn"
                   placeholder="ACN"
                   type="text"
            />
            <span class="form__error">{{ errors.acn }}</span>
          </label>

          <h4 class="titlePage titlePage_subtitle titlePage_green">primary contact</h4>


          <label class="form__label">
            <span class="form__labelTitle">E-mail*</span>
            <Field v-model="businessDetails.primary_details.primary_contact.email"
                   :rules="'required|email'"
                   class="form__input"
                   name="email"
                   placeholder="E-mail"
                   type="text"
            />

            <span class="form__error">{{ errors.email }}</span>
          </label>

          <label class="form__label">
            <span class="form__labelTitle">Mobile Number*</span>
            <Field v-model="businessDetails.primary_details.primary_contact.mobile"
                   v-maska="'## ## ### ###'"
                   :rules="{required: true, digits_without_whitespace: 10, min: 6}"
                   class="form__input"
                   label="mobile number"
                   name="phone"
                   placeholder="Mobile Number*"
            />
            <span class="form__error">{{ errors.phone }}</span>
          </label>

          <label class="form__label">
            <span class="form__labelTitle">Landline Number</span>
            <Field v-model="businessDetails.primary_details.primary_contact.phone"
                   v-maska="'## ## ### ###'"
                   :rules="{digits_without_whitespace: 10, min: 6}"
                   class="form__input"
                   label="landline number"
                   name="land_line"
                   placeholder="Landline Number"
            />
            <span class="form__error">{{ errors.land_line }}</span>
          </label>

        </div>
        <!-- Left column ends -->

        <!-- Right column starts -->
        <div
          class="tw-w-full tw-overflow-hidden md:tw-my-4 md:tw-px-4 md:tw-w-1/2 lg:tw-my-4 lg:tw-px-4 lg:tw-w-1/2 xl:tw-my-4 xl:tw-px-4 xl:tw-w-1/2">

          <h4 class="titlePage titlePage_subtitle titlePage_green">Business Address</h4>

          <BaseSingleAddressComponent
            v-if="businessDetails.primary_details.primary_address"
            ref="businessAddressREF"
            :address.sync="businessDetails.primary_details.primary_address ?? {}"
            @isValidAddress="(isAValidAddress) => relatedAddressIsValid(isAValidAddress)"
          />

          <h4 class="titlePage titlePage_subtitle titlePage_green">Shipping Address</h4>

          <transition name="fade">
            <BaseSingleAddressComponent
              v-if="!isShippingAddressSameAsMainAddress && businessDetails.shipping_address"
              ref="shippingAddressREF"
              :address.sync="businessDetails.shipping_address ?? {}"
              @isValidAddress="(isAValidAddress) => relatedAddressIsValid(isAValidAddress)"
            />
          </transition>

          <label class="label-switch switch-success">
            <input id="is_shipping_address_same_as_main_address"
                   v-model="isShippingAddressSameAsMainAddress"
                   class="switch switch-bootstrap status" name="status" type="checkbox"
                   value="1">
            <span class="lable"> Same as main address</span></label>


          <h4 class="titlePage titlePage_subtitle titlePage_green">Billing Address</h4>

          <transition name="fade">
            <BaseSingleAddressComponent
              v-if="!isBillingAddressSameAsMainAddress && businessDetails.billing_address"
              ref="billingAddressREF"
              :address.sync="businessDetails.billing_address ?? {}"
              @isValidAddress="(isAValidAddress) => relatedAddressIsValid(isAValidAddress)"
            />
          </transition>

          <label class="label-switch switch-success">
            <input id="is_billing_address_same_as_main_address"
                   v-model="isBillingAddressSameAsMainAddress"
                   class="switch switch-bootstrap status" name="status" type="checkbox"
                   value="1">
            <span class="lable"> Same as main address</span></label>


          <button
            class="btn btn_default tw-px-5 tw-bg-secondary tw-text-white tw-py-2 tw-float-right tw-mt-10"
            type="submit"
          >
            <svg-icon name="solid/floppy-disk"/>
            Save
          </button>

        </div>


        <!-- Right column ends -->

      </div>
    </Form>
  </div>
</template>

<script>
import GlobalMixins from '@/Mixins/GlobalMixins.js'
import AccountInformationEndPoints from "../../../routes/AccountInformationEndPoints";
import BaseSingleAddressComponent from "../../../components/commons/BaseSingleAddressComponent";
import {maska} from 'maska'
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import myUpload from 'vue-image-crop-upload';
import {useToast} from "vue-toastification";
import UploadImageAndCropVue from './UploadImageAndCrop.vue'
const toast = useToast();

export default {
  name: "AccountInformationBusinessDetailsIndex",
  directives: {
    maska
  },
  mixins: [GlobalMixins],
  components: {
    BaseSingleAddressComponent,
    Cropper,
    myUpload,
    UploadImageAndCropVue,
  },
  data() {
    return {
      businessDetails: {
        primary_details: {
          primary_contact: {},
          primary_address: {}
        }
      },
      show: false,
      sameAsBusinessName: false,
      isShippingAddressSameAsMainAddress: false,
      isBillingAddressSameAsMainAddress: false
    }
  },
  async mounted() {

    this.startLoader()

    try {
      this.businessDetails = (await AccountInformationEndPoints.getBusinessDetails()).data.data

      this.sameAsBusinessName = this.businessDetails.business_name === this.businessDetails.trading_name
      this.isShippingAddressSameAsMainAddress = Boolean(this.businessDetails.shipping_address.is_shipping_address_same_as_main_address)
      this.isBillingAddressSameAsMainAddress = Boolean(this.businessDetails.billing_address.is_billing_address_same_as_main_address)

    } catch (error) {
      this.showErrorModal(error)
      this.stopLoader()
    }
    this.stopLoader()
  },
  watch: {
    sameAsBusinessName(value) {
      if (value && this.businessDetails.business_name) {
        this.businessDetails.trading_name = this.businessDetails.business_name
      }
    },
    'businessDetails.business_name' (){
      if(this.sameAsBusinessName) {
        this.businessDetails.trading_name = this.businessDetails.business_name
      }
    },
  },
  methods: {
    async cropSuccess(imgDataUrl, field) {

      this.startLoader()

      try {
        this.businessDetails.logo_url = (await AccountInformationEndPoints.changeImage({base_64_image: imgDataUrl})).data.logo_url
      } catch (error) {
        this.showErrorModal(error)
        this.stopLoader()
      }
      this.stopLoader()
      this.show = false

    },
    async removeLogo() {
      await this.cropSuccess(null)
    },
    async submitBusinessDetails() {

      //Check other address forms
      if (this.$refs.businessAddressREF) await this.$refs.businessAddressREF.validateForm()
      if (this.$refs.shippingAddressREF) await this.$refs.shippingAddressREF.validateForm()
      if (this.$refs.billingAddressREF) await this.$refs.billingAddressREF.validateForm()

      try {

        let payload = _.cloneDeep(this.businessDetails)

        payload.billing_address.is_billing_address_same_as_main_address = this.isBillingAddressSameAsMainAddress
        payload.shipping_address.is_shipping_address_same_as_main_address = this.isShippingAddressSameAsMainAddress

        payload.primary_address = this.formatAddress(payload.primary_details.primary_address)
        payload.billing_address = this.formatAddress(payload.billing_address)
        payload.shipping_address = this.formatAddress(payload.shipping_address)

        payload.primary_contact = _.pick(payload.primary_details.primary_contact, ['email', 'mobile', 'phone'])

        payload.primary_contact.mobile = payload.primary_contact.mobile?.replace(/\s/g, "")
        payload.primary_contact.phone = payload.primary_contact.phone?.replace(/\s/g, "")

        delete payload.primary_details
        delete payload.logo_url

        if (!payload.billing_address.is_billing_address_same_as_main_address && !payload.billing_address.address) return
        if (!payload.shipping_address.is_shipping_address_same_as_main_address && !payload.shipping_address.address) return

        this.startLoader()
        await AccountInformationEndPoints.updateBusinessDetails(payload, payload.id)

        toast.success('Business details successfully updated.')

      } catch (error) {
        this.showErrorModal(error)
        this.stopLoader()
      }
      this.stopLoader()

    },
    formatAddress(plainAddress) {
      if (plainAddress.state && _.isObject(plainAddress.state)) {
        plainAddress.state_id = plainAddress.state.state_id
        delete plainAddress.state
      }
      return plainAddress
    },
    async relatedAddressIsValid(isAValidAddress) {
      // console.log(isAValidAddress)
    }
  }

}
</script>

<style>


</style>
