import {httpReq} from "@/htttpReq";

const routePrefix = 'ordering-portal/account-information-maintenance'

export default {

  //Business details
  getBusinessDetails: () => httpReq.get(`${routePrefix}/business-details`),
  updateBusinessDetails: (payload, profileId) => httpReq.put(`${routePrefix}/business-details/${profileId}`, payload),
  changeImage: (payload) => httpReq.post(`${routePrefix}/business-details/update-logo`, payload),

}
