<script setup>
import { ref, onBeforeUnmount } from "vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import Modal from "@/components/item-wizard/components/elements/Modal.vue";

// This function is used to detect the actual image type,
function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = "";
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}

const image = ref({ src: null, type: null });
const file = ref(null);
const cropperref = ref(null);
const emits = defineEmits(["cropSuccess", "close"]);
const error = ref(null);

function crop() {
  const { canvas } = cropperref.value.getResult();
  const base64URL = canvas.toDataURL("png");
  emits("cropSuccess", base64URL);
}

function reset() {
  image.value = {
    src: null,
    type: null,
  };
}

const show = ref(true);

onBeforeUnmount(() => {
  if (image.value.src) {
    URL.revokeObjectURL(image.value.src);
  }
});

function loadImage(event) {
  error.value = null;
  // Reference to the DOM input element
  const { files } = event.target;
  // Ensure that you have a file before attempting to read it
  if (files && files[0]) {
    // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (image.value.src) {
      URL.revokeObjectURL(image.value.src);
    }

    if (files[0].size > 500000) {
      error.value = "File size should not exceed 500KB";
      return;
    }
    // 2. Create the blob link to the file to optimize performance:
    const blob = URL.createObjectURL(files[0]);

    // 3. The steps below are designated to determine a file mime type to use it during the
    // getting of a cropped image from the canvas. You can replace it them by the following string,
    // but the type will be derived from the extension and it can lead to an incorrect result:
    //
    // image.value = {
    //    src: blob;
    //    type: files[0].type
    // }

    // Create a new FileReader to read this image binary data
    const reader = new FileReader();
    // Define a callback function to run, when FileReader finishes its job
    reader.onload = (e) => {
      // Note: arrow function used here, so that "image.value" refers to the image of Vue component
      image.value = {
        // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
        src: blob,
        // Determine the image type to preserve it during the extracting the image from canvas:
        type: getMimeType(e.target.result, files[0].type),
      };
    };
    // Start the reader job - read file as a data url (base64 format)
    reader.readAsArrayBuffer(files[0]);
  }
}
</script>

<template>
  <Modal :modelValue="show" @update:modelValue="$emit('close')" title="Upload Image and Crop">
    <div class="tw-bg-white tw-w-full">
      <div>
        <button class="tw-w-full tw-h-10 tw-cursor-pointer" @click="$refs.file.click()">
          <input
            class="tw-hidden"
            type="file"
            ref="file"
            @change="loadImage($event)"
            accept="image/jpeg, image/png"
          />
          Upload Image
        </button>
        <p class="tw-my-2 tw-text-gray-400 tw-text-sm">
          JPG, JPEG, PNG | Max 500KB | Aspect Ratio: 1:5
        </p>

        <p v-if="error" class="tw-text-sm tw-text-red-600">{{ error }}</p>
      </div>

      <div style="height: 300px; max-height: 90vh;">
        <cropper
          ref="cropperref"
          class="upload-example-cropper"
          :src="image.src"
          :stencil-props="{
            aspectRatio: 5 / 1,
          }"
        />
      </div>
      <button
        type="button"
        v-if="image.src"
        class="btn btn_default tw-px-5 tw-bg-secondary tw-text-white tw-py-2"
        @click="crop"
      >
        Save
      </button>
    </div>
  </Modal>
</template>

<style scoped>
::v-deep .modal {
    width: 100%;
    max-width: 90vw;

    @media (min-width: 768px) {
        width: 500px;
    }
}
</style>